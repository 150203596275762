import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"pa-lg-5",attrs:{"rounded":_vm.$config.shaped_widgets}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("products"))+" "),_c(VSpacer),_c('add-products-modal',{attrs:{"participant":_vm.participant,"outlined":true,"small":true,"color":"primary","icon":"mdi-plus","button_text":"addProduct"},on:{"refresh":function($event){return _vm.getProducts()}}})],1),_c(VCardText,[_c(VDataTable,{attrs:{"loading":_vm.loading,"headers":_vm.service_headers,"items":_vm.participant_services,"sort-by":"name","item-key":"uuid"},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(_vm._s(_vm.parseProductName(item.name))+" ")]}},{key:"item.balance",fn:function({ item }){return [(item.unit == 'EURO')?_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.balance)))]):_c('span',[_vm._v(_vm._s(item.balance))])]}},{key:"item.unit",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item.unit))+" ")]}},{key:"item.start_date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.start_date))+" ")]}},{key:"item.end_date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.end_date))+" ")]}},{key:"item.count",fn:function({ item }){return [_vm._v(" "+_vm._s(item.count)+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('add-budget-modal',{attrs:{"budget_id":item.id,"accessToken":_vm.participant.accessToken},on:{"refresh":function($event){return _vm.getProducts()}}}),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteBudget(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }