<template>
    <v-content>
        <v-toolbar
            class="wide-toolbar mb-4"
            :class="$vuetify.breakpoint.mdAndDown ? 'mt-4' : ''"
            color="transparent"
            flat
        >
            <v-btn text @click="$router.push('/requests')">
                <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-arrow-left</v-icon>
                <span v-if="$vuetify.breakpoint.smAndUp">{{ $t("allRequests") }}</span>
            </v-btn>
            <v-spacer></v-spacer>

            <v-dialog
                scrollable
                v-model="add_product_modal"
                :fullscreen="$vuetify.breakpoint.mdAndDown"
                max-width="600"
                v-if="formattedParticipant.accessToken"
            >
                <v-card>
                    <v-toolbar flat color="primary" dark>
                        {{ $t("addProduct") }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="add_product_modal = false"><v-icon>mdi-close</v-icon></v-btn>
                    </v-toolbar>
                    <v-card-text class="ma-0 pa-0">
                        <add-products-comp
                            :filter="'Mantelzorgwaardering'"
                            :participant="formattedParticipant"
                            @addedProduct="handleProductAdded"
                        ></add-products-comp>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="addProductCheck()" color="primary">
                            {{ $t("save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                scrollable
                v-model="confirm_submit_modal"
                :fullscreen="$vuetify.breakpoint.mdAndDown"
                max-width="900"
            >
                <v-card>
                    <v-toolbar flat color="primary" dark>
                        {{ $t("confirmEmailText") }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="confirm_submit_modal = false"><v-icon>mdi-close</v-icon></v-btn>
                    </v-toolbar>
                    <v-card-text class="pa-lg-5">
                        <v-text-field
                            v-model="formrequest.in_progress_remark"
                            :label="$t('inProgressRemark')"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-text class="ma-0 pa-0">
                        <v-form ref="confirm_submit_form">
                            <vue-editor :editorToolbar="customToolbar" v-model="formrequest.approval_remark">
                            </vue-editor>
                        </v-form>
                    </v-card-text>
                    <v-footer>
                        <v-btn
                            @click="modalOrigin === 'save' ? sendMail() : confirmProvisioning()"
                            color="primary"
                            block
                            x-large
                            plain
                        >
                            {{ $t("saveAndSendEmail") }}
                        </v-btn>
                    </v-footer>
                </v-card>
            </v-dialog>

            <v-btn
                rounded
                :disabled="formrequest.approval_status !== 'OPEN'"
                :color="formrequest.approval_status === 'OPEN' ? 'primary' : 'secondary'"
                @click="save('IN_PROGRESS')"
                class="mr-3"
                v-if="formrequest.approval_status === 'OPEN' || formrequest.approval_status === 'IN_PROGRESS'"
            >
                <span v-if="$vuetify.breakpoint.smAndUp">{{
                    formrequest.approval_status === "OPEN" ? $t("in behandeling nemen") : $t("in behandeling")
                }}</span>
                <v-icon v-if="formrequest.approval_status === 'OPEN'" :right="$vuetify.breakpoint.smAndUp"
                    >mdi-progress-pencil</v-icon
                >
                <v-icon v-if="formrequest.approval_status === 'IN_PROGRESS'" :right="$vuetify.breakpoint.smAndUp"
                    >mdi-progress-close</v-icon
                >
            </v-btn>

            <v-btn
                rounded
                :disabled="
                    formrequest.approval_status !== 'OPEN' &&
                    formrequest.approval_status !== 'IN_PROGRESS' &&
                    formrequest.approval_status !== 'FAILED'
                "
                color="success"
                @click="save('APPROVED')"
                class="mr-3"
                v-if="formattedParticipant.accessToken"
            >
                <span v-if="$vuetify.breakpoint.smAndUp"> {{ $t("approve") }}</span>
                <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-checkbox-marked-circle</v-icon>
            </v-btn>

            <v-btn
                rounded
                :disabled="
                    formrequest.approval_status !== 'OPEN' &&
                    formrequest.approval_status !== 'IN_PROGRESS' &&
                    formrequest.approval_status !== 'FAILED'
                "
                color="success"
                @click="saveWithoutUser()"
                class="mr-3"
                v-if="!formattedParticipant.accessToken"
            >
                <span v-if="$vuetify.breakpoint.smAndUp"> {{ $t("approveWithoutCard") }}</span>
                <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-checkbox-marked-circle</v-icon>
            </v-btn>

            <v-btn
                rounded
                :disabled="
                    formrequest.approval_status !== 'OPEN' &&
                    formrequest.approval_status !== 'IN_PROGRESS' &&
                    formrequest.approval_status !== 'FAILED'
                "
                color="error"
                @click="save('DISAPPROVED')"
            >
                <span v-if="$vuetify.breakpoint.smAndUp"> {{ $t("disapprove") }}</span>
                <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-close-circle</v-icon>
            </v-btn>
        </v-toolbar>
        <v-row v-if="formrequest.extension">
            <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-card color="transparent" flat>
                    <v-card-title>Verlenging</v-card-title>
                    <v-card-subtitle></v-card-subtitle>
                </v-card>
            </v-col>
            <v-col>
                <v-alert outlined transition type="info" prominent border="left" color="info">
                    {{ $t("Deze persoon heeft aangegeven dat dit een verlenging is.") }}
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-card color="transparent" flat>
                    <v-card-title>Status aanvraag</v-card-title>
                    <v-card-subtitle></v-card-subtitle>
                </v-card>
            </v-col>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <v-card-title>Status aanvraag</v-card-title>
                        <v-card-subtitle></v-card-subtitle>
                    </template>
                    <v-card-text v-if="formrequest.approval_status == 'OPEN'"> {{ $t("Open") }} </v-card-text>
                    <v-card-text v-if="formrequest.approval_status == 'IN_PROGRESS'">
                        {{ $t("In behandeling") }}
                        <span v-if="formrequest.in_progress_date">
                            <br /><br />
                            Op {{ formrequest.in_progress_date | moment }}
                            {{ $t("is deze aanvraag in behandeling genomen door") }}
                            {{ formrequest.in_progress_by_user_id.first_name }}
                            {{ formrequest.in_progress_by_user_id.last_name }}.
                        </span>
                        <span v-if="formrequest.in_progress_remark">
                            <br /><br />
                            {{ $t("Opmerking") }}: "{{ formrequest.in_progress_remark }}"
                        </span>
                    </v-card-text>
                    <v-card-text v-if="formrequest.approval_status == 'APPROVED'">
                        {{ $t("requestIsApproved") }}
                        <span v-if="formrequest.in_progress_date">
                            <br /><br />
                            Op {{ formrequest.in_progress_date | moment }}
                            {{ $t("is deze aanvraag in behandeling genomen door") }}
                            {{ formrequest.in_progress_by_user_id.first_name }}
                            {{ formrequest.in_progress_by_user_id.last_name }}.
                        </span>
                        <br />
                        <br />
                        <span v-if="formrequest.approval_date">
                            Op {{ formrequest.approval_date | moment }} {{ $t("is deze aanvraag goedgekeurd door") }}
                            <span v-if="formrequest.approved_by_user_id">
                                {{ formrequest.approved_by_user_id.first_name }}
                                {{ formrequest.approved_by_user_id.last_name }}.
                            </span>
                            <span v-else>
                                {{ $t("tijdens bulkverwerking.") }}
                            </span>
                        </span>
                    </v-card-text>
                    <v-card-text v-if="formrequest.approval_status == 'DISAPPROVED'">
                        {{ $t("requestIsDisapproved") }}
                        <span v-if="formrequest.in_progress_date">
                            <br /><br />
                            Op {{ formrequest.in_progress_date | moment }}
                            {{ $t("is deze aanvraag in behandeling genomen door") }}
                            {{ formrequest.in_progress_by_user_id.first_name }}
                            {{ formrequest.in_progress_by_user_id.last_name }}.
                        </span>
                        <br />
                        <br />
                        <span v-if="formrequest.approval_date">
                            Op {{ formrequest.approval_date | moment }} {{ $t("is deze aanvraag afgekeurd door") }}
                            {{ formrequest.approved_by_user_id.first_name }}
                            {{ formrequest.approved_by_user_id.last_name }}.
                        </span>
                    </v-card-text>
                    <v-card-text v-if="formrequest.approval_status == 'FAILED'">
                        <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-alert</v-icon>
                        {{ $t("Er is iets fout gegaan bij het verwerken van deze aanvraag. Probeer het opnieuw.") }}
                        <br /><br />
                        {{ $t("Als het nogmaals fout gaat, neem dan contact op met support.") }}
                        <span v-if="formrequest.in_progress_date">
                            <br /><br />
                            Op {{ formrequest.in_progress_date | moment }}
                            {{ $t("is deze aanvraag in behandeling genomen door") }}
                            {{ formrequest.in_progress_by_user_id.first_name }}
                            {{ formrequest.in_progress_by_user_id.last_name }}.
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="formattedParticipant.accessToken">
            <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-card color="transparent" flat>
                    <v-card-title>Geverifieerd kaartnummer</v-card-title>
                    <v-card-subtitle>Het geverifieerde kaartnummer van de mantelzorger</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <v-card-title>Geverifieerd kaartnummer</v-card-title>
                        <v-card-subtitle>Het geverifieerde kaartnummer van de mantelzorger</v-card-subtitle>
                    </template>
                    <v-card-text>
                        <field-row :value="formrequest.accesstoken" :label="$t('card')"></field-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            v-if="
                formrequest.caregiver.accesstoken &&
                !formrequest.accesstoken &&
                formrequest.approval_status != 'APPROVED'
            "
        >
            <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-card color="transparent" flat>
                    <v-card-title>Niet geverifieerd kaartnummer</v-card-title>
                    <v-card-subtitle>Dit kaartnummer is nog niet geverifieerd</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <v-card-title>Niet geverifieerd kaartnummer</v-card-title>
                        <v-card-subtitle>Dit kaartnummer is nog niet geverifieerd</v-card-subtitle>
                    </template>
                    <v-card-text>
                        <field-row :value="formrequest.caregiver.accesstoken" :label="$t('card')"></field-row>
                    </v-card-text>
                    <v-card-text>{{ $t("cardNotVerifiedText") }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="!formrequest.caregiver.accesstoken && !formattedParticipant.accessToken">
            <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-card color="transparent" flat>
                    <v-card-title>Nog geen kaartnummer bekend</v-card-title>
                    <v-card-subtitle>Deze persoon heeft aangegeven nog geen kaartnummer te hebben</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <v-card-title>Nog geen kaartnummer bekend</v-card-title>
                        <v-card-subtitle>Deze persoon heeft aangegeven nog geen kaartnummer te hebben</v-card-subtitle>
                    </template>
                    <v-card-text>
                        <field-row value="Nog geen kaart aanwezig" :label="$t('card')"></field-row>
                    </v-card-text>
                    <v-card-text>{{ $t("noCardKnownText") }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-card color="transparent" flat>
                    <v-card-title>Mantelzorger</v-card-title>
                    <v-card-subtitle>Alle gegevens van de mantelzorger</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <v-card-title>Mantelzorger</v-card-title>
                        <v-card-subtitle>Alle gegevens van de mantelzorger</v-card-subtitle>
                    </template>
                    <v-card-text>
                        <v-text-field
                            v-model="formrequest.caregiver.prefix"
                            :label="$t('prefix')"
                            :disabled="disabled"
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.caregiver.first_name"
                            :label="$t('firstName')"
                            :rules="[required]"
                            validate-on-blur
                            :disabled="disabled"
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.caregiver.preposition"
                            :label="$t('preposition')"
                            :disabled="disabled"
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.caregiver.last_name"
                            :label="$t('lastName')"
                            :rules="[required]"
                            validate-on-blur
                            :disabled="disabled"
                        ></v-text-field>

                        <date-picker
                            v-model="formrequest.caregiver.date_of_birth"
                            :label="$t('dateOfBirth')"
                            :disabled="disabled"
                        />

                        <v-text-field
                            v-model="formrequest.caregiver.phonenumber"
                            :label="$t('phoneNumber')"
                            :rules="[required]"
                            v-mask="'##########'"
                            validate-on-blur
                            :disabled="disabled"
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.caregiver.email"
                            :label="$t('email')"
                            :rules="emailRules"
                            validate-on-blur
                            :disabled="disabled"
                        ></v-text-field>
                        <address-lookup :disabled="disabled" :address="formrequest.caregiver"></address-lookup>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp && $config.show_carereceiver_address" class="my-9"></v-divider>
        <v-row v-if="$config.show_carereceiver_address">
            <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-card color="transparent" flat>
                    <v-card-title>Zorgontvanger</v-card-title>
                    <v-card-subtitle>Alle gegevens van de zorgontvanger</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <v-card-title>Zorgontvanger</v-card-title>
                        <v-card-subtitle>Alle gegevens van de zorgontvanger</v-card-subtitle>
                    </template>
                    <v-card-text>
                        <v-text-field
                            v-model="formrequest.carereceiver.prefix"
                            :label="$t('prefix')"
                            :disabled="disabled"
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.carereceiver.first_name"
                            :label="$t('firstName')"
                            :disabled="disabled"
                            :rules="[required]"
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.carereceiver.preposition"
                            :label="$t('preposition')"
                            :disabled="disabled"
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.carereceiver.last_name"
                            :label="$t('lastName')"
                            :rules="[required]"
                            validate-on-blur
                            :disabled="disabled"
                        ></v-text-field>

                        <date-picker
                            v-model="formrequest.carereceiver.date_of_birth"
                            :label="$t('dateOfBirth')"
                            :disabled="disabled"
                        />

                        <v-text-field
                            v-model="formrequest.carereceiver.phonenumber"
                            :label="$t('phoneNumber')"
                            :rules="[required]"
                            v-mask="'##########'"
                            validate-on-blur
                            :disabled="disabled"
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.carereceiver.email"
                            :label="$t('email')"
                            :rules="emailRules"
                            validate-on-blur
                            :disabled="disabled"
                        ></v-text-field>
                        <address-lookup :disabled="disabled" :address="formrequest.carereceiver"></address-lookup>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp" class="my-9"></v-divider>
        <v-row>
            <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-card color="transparent" flat>
                    <v-card-title>Mantelzorg vragen</v-card-title>
                    <v-card-subtitle>Inhoudelijke vragen m.b.t. mantelzorg & ondertekening</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <v-card-title>Mantelzorg vragen</v-card-title>
                        <v-card-subtitle>Inhoudelijke vragen m.b.t. mantelzorg & ondertekening</v-card-subtitle>
                    </template>
                    <v-card-text>
                        <v-textarea
                            :label="$t('caregiverDuties')"
                            v-model="formrequest.questions.caregiver_duties"
                            :disabled="disabled"
                        ></v-textarea>
                        <v-text-field
                            v-model="formrequest.questions.hours_per_week"
                            :label="$t('hoursCarePerWeek')"
                            type="number"
                            min="0"
                            validate-on-blur
                            disabled
                            v-mask="'###'"
                        ></v-text-field>
                        <v-text-field :value="formattedSinceDate" :label="$t('Verleent zorg sinds')" disabled>
                        </v-text-field>
                        <v-textarea
                            :label="$t('carereceiverClinicalPicture')"
                            :value="formrequest.questions.carereceiver_clinical_picture"
                            :disabled="disabled"
                        ></v-textarea>
                        <v-textarea
                            :label="$t('relationship')"
                            :value="formrequest.questions.relationship"
                            :disabled="disabled"
                        ></v-textarea>

                        <v-text-field :value="formrequest.terms.place" :label="$t('signedAt')" disabled></v-text-field>

                        <v-text-field
                            :value="formrequest.terms.date | moment('DD-MM-YYYY')"
                            :label="$t('signedOn')"
                            disabled
                        >
                        </v-text-field>

                        <v-checkbox
                            v-model="formrequest.terms.agreed_privacy_policy"
                            :label="$t('agreeWithPrivacyPolicy')"
                            disabled
                        ></v-checkbox>
                        <v-checkbox
                            v-model="formrequest.terms.agreed_conditions"
                            :label="$t('agreeWithTerms')"
                            disabled
                        ></v-checkbox>
                        <v-checkbox
                            v-model="formrequest.terms.newsletter"
                            :label="$t('isSubscribedToCareNewsletter')"
                            disabled
                        ></v-checkbox>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp && formrequest.filled_in_by" class="my-9"></v-divider>
        <v-row v-if="formrequest.filled_in_by">
            <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-card color="transparent" flat>
                    <v-card-title>Ingevuld door</v-card-title>
                    <v-card-subtitle>Deze aanvraag is ingevuld door de volgende persoon</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <v-card-title>Zorgontvanger</v-card-title>
                        <v-card-subtitle>Alle gegevens van de zorgontvanger</v-card-subtitle>
                    </template>
                    <v-card-text>
                        <v-text-field
                            v-model="formrequest.filled_in_by_name"
                            :label="$t('name')"
                            :disabled="disabled"
                            :rules="[required]"
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.filled_in_by_phone_number"
                            :label="$t('phoneNumber')"
                            :rules="[required]"
                            v-mask="'##########'"
                            validate-on-blur
                            :disabled="disabled"
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.filled_in_by_email"
                            :label="$t('email')"
                            :rules="emailRules"
                            validate-on-blur
                            :disabled="disabled"
                        ></v-text-field>
                        <v-text-field
                            v-model="formrequest.filled_in_by_relation"
                            :label="$t('Relatie tot mantelzorger')"
                            validate-on-blur
                            :disabled="disabled"
                        ></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-content>
</template>

<script>
import AddProductsComp from "../../components/AddProductsComp.vue";
import AddressLookup from "../../components/AddressLookup.vue";
import FieldRow from "../../components/FieldRow.vue";
import { VueEditor } from "vue2-editor";
import moment from "moment";

export default {
    components: {
        AddressLookup,
        FieldRow,
        AddProductsComp,
        VueEditor,
    },
    name: "Request",
    data() {
        return {
            product_added: false,
            loading: false,
            show: false,
            loading_address: false,
            confirm_submit_modal: false,
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
            formrequest: {
                carereceiver: {
                    prefix: "",
                    first_name: "",
                    preposition: "",
                    last_name: "",
                    date_of_birth: "",
                    phonenumber: "",
                    email: "",
                    postal_code: "",
                    housenumber: "",
                    housenumber_addition: "",
                    street: "",
                    city: "",
                },
                caregiver: {
                    prefix: "",
                    first_name: "",
                    preposition: "",
                    last_name: "",
                    date_of_birth: "",
                    phonenumber: "",
                    email: "",
                    postal_code: "",
                    housenumber: "",
                    housenumber_addition: "",
                    street: "",
                    city: "",
                    accesstoken: null,
                },
                questions: {
                    relationship: "",
                    caregiver_duties: "",
                    carereceiver_clinical_picture: "",
                    hours_per_week: "",
                },
                terms: {
                    place: "",
                    date: "",
                    agreed_privacy_policy: false,
                    agreed_conditions: false,
                    newsletter: false,
                },
                status: "DRAFT",
                accesstoken: null,
                approval_remark: null,
                filled_in_by: false,
                filled_in_by_name: "",
                filled_in_by_phone_number: "",
                filled_in_by_email: "",
                filled_in_by_relation: "",
                extension: false,
                since_date: "",
                in_progress_remark: null,
            },
            status_options: [
                {
                    text: this.$t("open"),
                    value: "OPEN",
                },
                {
                    text: this.$t("approved"),
                    value: "APPROVED",
                },
                {
                    text: this.$t("disapproved"),
                    value: "DISAPPROVED",
                },
            ],
            request_status: "OPEN",
            add_product_modal: false,
            required: (value) => !!value || this.$t("mandatory"),
            emailRules: [
                (v) => !!v || this.$t("mandatory"),
                (v) =>
                    !v ||
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) ||
                    this.$t("validEmail"),
            ],
            postcalCode: [
                (v) => !!v || this.$t("mandatory"),
                (v) => !v || /^(?:NL-)?(\d{4})\s*([A-Za-z]{2})$/.test(v) || this.$t("invalidPostalCode"),
            ],
            locked: true,
            approval_status: "OPEN",
            participant: { accessToken: null },
            participants: [],
            modalOrigin: "",
        };
    },
    created() {
        this.getRequest();
    },
    filters: {
        moment: function (date) {
            return moment(date).format("DD-MM-YYYY");
        },
    },
    methods: {
        sendMail(validateConfirmForm = true) {
            let email = {
                email: this.formrequest.caregiver.email,
                informal_care_request_id: this.formrequest.id,
                body: this.formrequest.approval_remark,
                subject: "Informatie over uw mantelzorg aanvraag",
            };
            this.$http
                .post(this.$config.backend_url + "/care-request-mail", email)
                .then(() => {
                    this.confirmedSave(validateConfirmForm);
                })
                .catch(() => {
                    this.loading = false;
                    this.$toast.error(this.$t("somethingWentWrong"));
                });
        },
        save(approval_status) {
            this.modalOrigin = "save"; // Set origin to 'save'

            if (approval_status == "APPROVED") {
                this.approval_status = "APPROVED";
                this.formrequest.approval_remark = this.$t("approvalRemarkApprovedText").replace(/<div/g, "<p");

                this.add_product_modal = true;
            }

            if (approval_status == "DISAPPROVED") {
                this.formrequest.approval_remark = this.$t("approvalRemarkDisapprovedText").replace(/<div/g, "<p");
                this.approval_status = "DISAPPROVED";

                this.confirm_submit_modal = true;
            }

            if (approval_status == "IN_PROGRESS") {
                this.approval_status = "IN_PROGRESS";
                this.formrequest.approval_remark = this.$t("approvalRemarkInProgressText").replace(/<div/g, "<p");

                this.confirm_submit_modal = true;
            }
        },
        saveWithoutUser() {
            this.modalOrigin = "saveWithoutUser"; // Set origin to 'saveWithoutUser'
            this.formrequest.approval_remark = this.$t("approvalRemarkApprovedNewCardText").replace(/<div/g, "<p");

            this.confirm_submit_modal = true; // Open the modal
        },
        addProductCheck() {
            // check if the user has added a product when status is approved
            if (!this.product_added && this.approval_status == "APPROVED") {
                this.$toast.error(this.$t("Je hebt nog geen product toegevoegd"));
                return;
            }

            // there is a product or status is not approved, so we can close product modal and open confirm modal
            this.add_product_modal = false;
            this.confirm_submit_modal = true;
        },
        confirmProvisioning() {
            this.loading = true;

            this.$http
                .post(this.$config.backend_url + "/participants/register-participant", this.formrequest.caregiver)
                .then(async () => {
                    this.approval_status = "APPROVED";
                    await this.getParticipants();

                    await this.sendMail(false);
                })
                .catch(() => {
                    this.loading = false;
                    this.$toast.error(this.$t("somethingWentWrong"));
                });

            // Reset the modal origin
            this.modalOrigin = "";
        },
        confirmedSave(validateForm = true) {
            if (validateForm && !this.$refs.confirm_submit_form.validate()) {
                return;
            }

            this.confirm_submit_modal = false;

            this.loading = true;
            this.formrequest.approval_status = this.approval_status;

            this.$http
                .patch(this.$config.backend_url + "/care-requests/" + this.$route.params.id, this.formrequest)
                .then(() => {
                    this.loading = false;
                    this.$toast.success(this.$t("saved"));
                    this.getRequest();
                })
                .catch(() => {
                    this.loading = false;
                    this.$toast.error(this.$t("somethingWentWrong"));
                });
        },
        getRequest() {
            this.loading = true;
            this.$http.get(this.$config.backend_url + "/care-requests/" + this.$route.params.id).then((response) => {
                this.formrequest = response.data.informalcare_request;
                if (!this.formrequest.approval_status) {
                    this.formrequest.approval_status = "OPEN";
                }
                if (this.formrequest.approval_status != "OPEN") {
                    this.locked = true;
                }

                let user = this.$store.getters.getParticipants.find(
                    (s_request) => s_request.id == this.formrequest.user.participant_id
                );
                if (user) {
                    this.formrequest.accesstoken = user.accessToken;
                }

                this.loading = false;
            });
        },
        getParticipants() {
            this.loading = true;
            this.$http.get(this.$config.backend_url + "/users").then((response) => {
                this.participants = response.data.body.statusResults;
                this.$store.dispatch("setParticipants", this.participants);
                this.loading = false;
            });
        },
        getName(person) {
            let prefix = person.prefix ? person.prefix + " " : "";
            let preposition = person.preposition ? " " + person.preposition + " " : " ";

            return prefix + person.first_name + preposition + person.last_name;
        },
        emptyIfNull(value) {
            return value ? value + " " : " ";
        },
        approvalStatus(status) {
            if (status == "APPROVED") {
                return {
                    icon: "mdi-check",
                    color: "success",
                };
            }
            if (status == "DISAPPROVED") {
                return {
                    icon: "mdi-check",
                    color: "error",
                };
            }

            return {
                icon: "mdi-check",
                color: null,
            };
        },
        handleProductAdded() {
            this.product_added = true;
        },
    },
    computed: {
        disabled() {
            if (this.locked) {
                return true;
            }

            return true;
        },
        formattedParticipant() {
            let participant = { ...this.participant };
            participant.accessToken = this.formrequest.accesstoken || null;

            return participant;
        },
        formattedSinceDate() {
            // Check if the since_date is set and is a valid date
            if (this.formrequest.since_date) {
                return this.$moment(this.formrequest.since_date).format("DD-MM-YYYY");
            } else {
                return "-";
            }
        },
    },
    watch: {
        "formrequest.accesstoken": function (newToken) {
            this.participant.accessToken = newToken;
        },
    },
};
</script>

<style lang="scss">
.wide-toolbar .v-toolbar__content,
.v-toolbar__extension {
    padding: 4px 0px;
}

.uppercase input {
    text-transform: uppercase;
}
</style>
